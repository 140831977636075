import { Subscript } from "@mui/icons-material";
import StoreOrdersList from "screens/DeliveryPartner/StoreOrdersList";
import EnquiryContainer from "screens/Enquiry";
import OrderHistory from "screens/Global/OrderHistory";
import DeliveryPartnerWallet from "screens/Global/Wallet/DeliveryPartnerWallet";
import StoreSettlements from "screens/Global/store_settlements";

import LandingPage from "screens/LandingPage";
import OTPVerification from "screens/Login/OTPVerification";
import Selector from "screens/Login/Selector";
import SignIn from "screens/Login/SignIn";
import MerchantAccount from "screens/Merchant/MerchantAccount";
import MerchantDashboard from "screens/Merchant/MerchantDashboard";
import MerchantProfile from "screens/Merchant/MerchantProfile";
import MerchantQueries from "screens/Merchant/MerchantQueries";
import OrderDetails from "screens/Merchant/OrderDetails";
import Settlements from "screens/Merchant/Settlements";
import OrderSuccess from "screens/Store/OrderSuccess";
import StoreCart from "screens/Store/StoreCart";
import StoreProfile from "screens/Store/StoreProfile";
import StoreQueriesList from "screens/Store/StoreQueriesList";
const routes = [
	{
		key: 1,
		name: "Landing",
		icon: <Subscript />,
		route: "/",
		component: <SignIn />,
	},
	{
		key: 2,
		name: "Query",
		icon: <Subscript />,
		route: "/query",
		component: <EnquiryContainer />,
	},
	{
		key: 19,
		name: "Query",
		icon: <Subscript />,
		route: "/login",
		component: <SignIn />,
	},
	{
		key: 17,
		name: "OTP",
		icon: <Subscript />,
		route: "/otp",
		component: <OTPVerification />,
	},
	{
		key: 18,
		name: "StoreProfile",
		icon: <Subscript />,
		route: "/store-profile",
		component: <StoreProfile />,
	},
	{
		key: 1,
		name: "StoreListing",
		icon: <Subscript />,
		route: "/store-queries",
		component: <StoreQueriesList />,
	},
	{
		key: 16,
		name: "MerchantProfile",
		icon: <Subscript />,
		route: "/merchant-profile",
		component: <MerchantProfile />,
	},
	{
		key: 15,
		name: "MerchantAccount",
		icon: <Subscript />,
		route: "/merchant-account",
		component: <MerchantAccount />,
	},
	{
		key: 14,
		name: "MerchantQueries",
		icon: <Subscript />,
		route: "/merchant-queries",
		component: <MerchantQueries />,
	},
	{
		key: 13,
		name: "StoreCart",
		icon: <Subscript />,
		route: "/store-cart",
		component: <StoreCart />,
	},
	{
		key: 12,
		name: "Success",
		icon: <Subscript />,
		route: "/order-success",
		component: <OrderSuccess />,
	},
	{
		key: 20,
		name: "MerchantDashboard",
		icon: <Subscript />,
		route: "/m-dashboard",
		component: <MerchantDashboard />,
	},
	{
		key: 21,
		name: "OrderDetails",
		icon: <Subscript />,
		route: "/order-detail",
		component: <OrderDetails />,
	},
	{
		key: 22,
		name: "Settlements",
		icon: <Subscript />,
		route: "/settlements",
		component: <Settlements />,
	},
	{
		key: 23,
		name: "StoreSettlement",
		icon: <Subscript />,
		route: "/settlements/store",
		component: <StoreSettlements />,
	},
	{
		key: 24,
		name: "SettlementHistory",
		icon: <Subscript />,
		route: "/settlements/store/history",
		component: <OrderHistory />,
	},
	{
		key: 25,
		name: "StoreOrdersList",
		icon: <Subscript />,
		route: "/store/orders-list",
		component: <StoreOrdersList />,
	},
	{
		key: 26,
		name: "DeliveryPartnerWallet",
		icon: <Subscript />,
		route: "/wallets/partner",
		component: <DeliveryPartnerWallet />,
	},
	{
		key: 27,
		name: "Selector",
		icon: <Subscript />,
		route: "/selector",
		component: <Selector />,
	},
];
export default routes;
