const { default: jambuda } = require("./core");

const queries = { getMerchantQueries, submitMerchantQueryResponse };
export default queries;

function getMerchantQueries(merchantId, status, page, count) {
	return new Promise((resolve, reject) => {
		jambuda
			.get(
				`/api/merchant-query/by-merchant/id?merchantId=${merchantId}&page=${page}&count=${count}&status=${status}`
			)
			.then((response) => {
				const { totalCount, merchantQueries } = response.data;
				return resolve({ totalCount, merchantQueries });
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function submitMerchantQueryResponse(payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.post(`api/query-response`, payload)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
