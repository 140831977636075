const { default: jambuda } = require("./core");
const productVariant = { getProductVariants };
function getProductVariants(brand, product) {
	return new Promise((resolve, reject) => {
		if (brand && product) {
			jambuda
				.get(`/api/product-variants?brand=${brand}&product=${product}`)
				.then((result) => {
					const variants = result.data;
					const count = result.data.length;
					return resolve({ variants: variants, count: count });
				});
		} else {
			jambuda.get(`/api/product-variants`).then((result) => {
				const variants = result.data;
				const count = result.data.length;
				return resolve({ variants: variants, count: count });
			});
		}
	});
}

export default productVariant;
