import { useSelector } from "react-redux";
import queries from "../../api/queries";
import React, { useEffect, useState } from "react";
import {
	Box,
	Checkbox,
	Container,
	Dialog,
	DialogContent,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	Paper,
	Snackbar,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import MKBreadCrumbs from "components/MKBreadCrumbs";
import _ from "lodash";
import MKTypography from "components/MKTypography";
import { AddShoppingCart, CheckCircle } from "@mui/icons-material";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MKSwipable from "components/MKSwipable";
import { DataGrid } from "@mui/x-data-grid";
import { CiSaveUp1 } from "react-icons/ci";
import { VscSyncIgnored } from "react-icons/vsc";
import MKCountdown from "components/MKCountDown";
import { IoDocumentAttach } from "react-icons/io5";
const breadcrumb = [
	{ id: 1, name: "Home", link: "/" },
	{ id: 2, name: "My Queries", link: "" },
];

const RowDetail = ({ queryText }) => {
	return (
		<div style={{ borderTop: "1px solid #ccc", width: "100%" }}>
			{queryText}
		</div>
	);
};

const MerchantQueries = (props) => {
	const [myQueries, setMyQueries] = useState([]);
	const merchant = useSelector((state) => state.merchants.merchant);
	const products = useSelector((state) => state.products.products);
	const brands = useSelector((state) => state.brands.brands);
	const models = useSelector((state) => state.models.models);
	const variants = useSelector((state) => state.variants.variants);
	const [expiredQueries, setExpiredQueries] = useState([]);
	const [activeTab, setTabValue] = useState(0);
	const [mechantQueryResponse, setMerchantQueryResponse] = useState([]);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [unitPrice, setUnitPrice] = useState({});
	const [unavailableUnits, setUnavailableUnits] = useState({});
	const [imageToShow, setImageToShow] = useState("");
	const [showImageDialog, setShowImageDialog] = useState(false);
	const handleUnitPriceUpdate = (row, e) => {
		let currentUnitPrice = { ...unitPrice };
		currentUnitPrice[row.merchantQueryId] = e.target.value;
		setUnitPrice(currentUnitPrice);
	};
	const handleExpiry = (row) => {
		let current = [...expiredQueries];
		current.push(row.id);
		setExpiredQueries(current);
	};
	useEffect(() => {
		queries
			.getMerchantQueries(merchant.id, "RESPONSE_AWAITING", 1, 50)
			.then((response) => {
				const { totalCount, merchantQueries } = response;
				const ret = _.map(merchantQueries, (query) => {
					return buildQuery(query);
				});
				setMyQueries(ret);
			});
	}, []);

	const submitMerchantQueryResponse = (query) => {
		let unavailable = false;
		if (unavailableUnits[query.id]) {
			unavailable = true;
		}
		const payload = {
			MerchantQuery: { connect: { id: query.merchantQueryId } },
			unavailable: unavailable,
			response: "",
			responseType: "QUOTE",

			quotedPricePerUnit: {
				currency: "AED",
				amount: unitPrice[query.merchantQueryId],
			},
			ProductQuery: { connect: { id: query.productQueryId } },
			Merchant: { connect: { id: query.merchantId } },
			QueryGroup: { connect: { id: query.queryGroupId } },
		};
		console.log("MERCHANT RESPONSE", payload);
		queries
			.submitMerchantQueryResponse(payload)
			.then((response) => {
				setOpen(true);
				setMessage("Your quote is submitted.");
				const remaining = _.filter(myQueries, (q) => {
					return q.merchantQueryId != query.merchantQueryId;
				});
				setMyQueries(remaining);
			})
			.catch((err) => {
				console.log(err);
				setOpen(true);
				setMessage("FAILED! to submit quote");
			});
	};

	const buildQuery = (query) => {
		const productQuery = query.ProductQuery;

		const brand = _.find(brands, (b) => {
			return b.id == productQuery.brandId;
		});
		const model = _.find(models, (m) => {
			return m.id == productQuery.modelsId;
		});
		const quantity = productQuery.quantity;
		const color = productQuery.color;
		const product = _.find(products, (p) => {
			return p.id == productQuery.productId;
		});
		const queryText = productQuery.queryText;

		let quality = "Original";
		if (productQuery.qualityVariant == "FIRST_COPY") {
			if (productQuery.variantId) {
				const variant = _.find(variants, (v) => {
					return v.id == productQuery.variantId;
				});
				quality = variant.name;
			} else {
				quality = "Other";
			}
		}

		const s = {
			id: query.id,
			image: productQuery.image,
			merchantQueryId: query.id,
			productQueryId: query.productQueryId,
			merchantId: query.merchantId,
			queryGroupId: query.queryGroupId,
			queryGroupName: query.queryGroupName,
			brand: brand,
			model: model,
			quality: quality,
			product: product,
			quantity: quantity,
			queryText: queryText,
			color: color,
			createdAt: productQuery.createdAt,
		};

		return s;
	};
	const handleUnavailable = (e, row) => {
		let current = { ...unavailableUnits };
		if (e.target.checked) {
			current[row.id] = true;
		} else {
			current[row.id] = false;
		}
		setUnavailableUnits(current);
	};
	const handleShowImage = (image) => {
		setImageToShow(image);
		setShowImageDialog(true);
	};

	const columns = [
		{
			field: "image",
			headerName: "",
			width: 100,
			renderCell: (params) => {
				console.log(params.row);
				return params.row.image ? (
					<IconButton onClick={() => handleShowImage(params.row.image)}>
						<IoDocumentAttach />
					</IconButton>
				) : (
					<></>
				);
			},
		},
		{
			field: "brand",
			headerName: "Brand",
			width: 100,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark'>
						{params.row?.brand?.name}
					</MKTypography>
				);
			},
		},
		{
			field: "model",
			headerName: "Model",
			width: 150,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark'>
						{params.row?.model?.name}
					</MKTypography>
				);
			},
		},
		{
			field: "color",
			headerName: "Color",
			width: 100,
			renderCell: (params) => {
				return (
					<Stack
						direction={"row"}
						sx={{
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
						gap={1}
					>
						<Box
							sx={{
								width: 14,
								height: 14,
								backgroundColor: params.row?.color.hex,
							}}
						></Box>
						<MKTypography variant='caption' color='dark'>
							{params.row?.color.name}
						</MKTypography>
					</Stack>
				);
			},
		},
		{
			field: "product",
			headerName: "Product",
			width: 120,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark'>
						{params.row?.product.name}
					</MKTypography>
				);
			},
		},
		{
			field: "quality",
			headerName: "Quality",
			width: 100,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark'>
						{params.row?.quality}
					</MKTypography>
				);
			},
		},
		{
			field: "quantity",
			headerName: "Quantity",
			width: 120,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark'>
						{params.row?.quantity}
					</MKTypography>
				);
			},
		},

		{
			field: "unavailable",
			headerName: "Availability",
			width: 150,
			renderCell: (params) => {
				return (
					<FormControlLabel
						control={
							<Checkbox
								onChange={(e) => handleUnavailable(e, params.row)}
								sx={{
									color: "#FB8C00",
									"&.Mui-checked": {
										color: "#FB8C00",
									},
								}}
							/>
						}
						label='Unavailable'
					/>
				);
			},
		},
		{
			field: "unitPrice",
			headerName: "Price",
			width: 150,
			renderCell: (params) => {
				return (
					<MKInput
						disabled={
							unavailableUnits[params.row.id] ||
							expiredQueries.includes(params.row.id)
						}
						label='Unit price'
						placeholder='Per Unit Price'
						size='small'
						onChange={(e) => handleUnitPriceUpdate(params.row, e)}
					/>
				);
			},
		},
		{
			field: "totalPrice",
			headerName: "Total",
			width: 150,
			renderCell: (params) => {
				return (
					<Stack direction='column' gap={1}>
						{unitPrice[params.row.id] && (
							<MKTypography
								color='dark'
								variant='caption'
								fontWeight='bold'
								fontSize={14}
							>
								AED &nbsp;
								{Number(unitPrice[params.row.id]) * Number(params.row.quantity)}
							</MKTypography>
						)}
					</Stack>
				);
			},
		},

		{
			field: "action",
			headerName: "Action",
			width: 150,
			renderCell: (params) => {
				return (
					<Stack direction={"row"} gap={1}>
						<Tooltip title='Submit'>
							<MKButton
								variant='contained'
								color='warning'
								size='small'
								onClick={() => submitMerchantQueryResponse(params.row)}
								disabled={expiredQueries.includes(params.row.id)}
							>
								<CiSaveUp1 /> &nbsp;Submit
							</MKButton>
						</Tooltip>
					</Stack>
				);
			},
		},
		{
			field: "countDown",
			headerName: "Expiring In",
			width: 150,
			renderCell: (params) => {
				return (
					<MKTypography color='error' variant='body2'>
						<MKCountdown
							createdAt={params.row.createdAt}
							onExpiry={() => handleExpiry(params.row)}
						/>
					</MKTypography>
				);
			},
		},
		{
			field: "remarks",
			headerName: "Remarks",
			width: 150,
			renderCell: (params) => {
				return (
					<MKTypography color='error' variant='caption'>
						{params.row.queryText}
					</MKTypography>
				);
			},
		},
	];
	const filterOutExpired = (query) => {
		setMyQueries((prevMyQueries) => {
			const exists1 = _.find(
				prevMyQueries,
				(q) => q.merchantQueryId === query.merchantQueryId
			);
			if (exists1) {
				console.log("Exists, so delete it from", prevMyQueries);
			}
			const remaining = _.filter(
				prevMyQueries,
				(q) => q.merchantQueryId !== query.merchantQueryId
			);
			console.log("After Removal", remaining);
			return remaining;
		});

		setExpiredQueries((prevExpiredQueries) => {
			const exists = _.find(
				prevExpiredQueries,
				(e) => e.merchantQueryId === query.merchantQueryId
			);
			if (!exists) {
				return [...prevExpiredQueries, query];
			}
			return prevExpiredQueries;
		});
	};
	const handleTabType = (e, val) => {
		console.log("ACTIVE TAB", val);
		setTabValue(val);
	};

	return (
		<div>
			<MKBreadCrumbs crumbs={breadcrumb} />
			<Snackbar
				open={open}
				autoHideDuration={4000}
				onClose={() => setOpen(false)}
				message={
					<MKTypography variant='caption' color='white'>
						{message}
					</MKTypography>
				}
				action={
					<MKButton
						iconOnly
						color='white'
						circular
						size='small'
						onClick={() => setOpen(false)}
					>
						<CheckCircle />
					</MKButton>
				}
			/>
			<Dialog open={showImageDialog} onClose={() => setShowImageDialog(false)}>
				<DialogContent>
					<div
						style={{ width: "500px", height: "500px", objectFit: "contain" }}
					>
						<img src={imageToShow} width='100%' height='100%' />
					</div>
				</DialogContent>
			</Dialog>
			<Paper
				sx={{
					bgcolor: "#ffffff",
					alignItems: "center",
					justifyContent: "center",
					padding: 10,
					display: "flex",
				}}
				elevation={0}
			>
				{myQueries.length ? (
					<div style={{ width: "100%" }}>
						<div style={{ width: "100%" }}>
							<DataGrid
								columns={columns}
								rows={myQueries}
								pageSizeOptions={[5]}
								disableRowSelectionOnClick
								autoHeight
							/>
						</div>
					</div>
				) : (
					<MKTypography variant='h3' color='dark'>
						No Active Enquiry
					</MKTypography>
				)}
			</Paper>
		</div>
	);
};

const ProductQuery = (props) => {
	const [query, setQuery] = useState(props.query);
	const [countdown, setCountdown] = useState("");
	const [unitPrice, setUnitPrice] = useState(0);

	const handleSubmit = () => {
		props.onSubmit(query, unitPrice);
	};
	useEffect(() => {
		const startCountdown = () => {
			const totalMilliseconds = 15 * 60 * 1000;
			const endTime = new Date(query.createdAt).getTime() + totalMilliseconds;

			const countdownInterval = setInterval(() => {
				const remainingTime = endTime - Date.now();
				if (remainingTime <= 0) {
					clearInterval(countdownInterval);
					setCountdown("Expired");

					props.onExpiry(query);
				} else {
					const minutes = Math.floor(remainingTime / (60 * 1000));
					const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);
					setCountdown(`${minutes} : ${seconds}`);
				}
			}, 1000);

			return () => clearInterval(countdownInterval);
		};

		startCountdown();
	}, [query.createdAt]);

	const handleExpiry = (row) => {
		console.log(`Row with ID ${row.id} has expired`);
		// Implement additional expiry handling logic here
	};

	return (
		<Paper sx={styles.queryRow} elevation={0}>
			<Stack
				direction='row'
				gap={8}
				sx={{ mt: 2, justifyContent: "space-around" }}
			>
				{" "}
				<Stack direction='column' sx={{ minWidth: 50 }}>
					<MKTypography variant='caption' color='dark'>
						Brand
					</MKTypography>
					<MKTypography variant='caption' color='dark'>
						{query?.brand?.name}
					</MKTypography>
				</Stack>
				<Stack direction='column' sx={{ width: 110 }}>
					<MKTypography variant='caption' color='dark'>
						Model
					</MKTypography>
					<MKTypography variant='caption' color='dark'>
						{query?.model?.name}
					</MKTypography>
				</Stack>
				<Stack direction='column' sx={{ width: 80 }}>
					<MKTypography variant='caption' color='dark'>
						Colour
					</MKTypography>
					<Stack direction={"row"} gap={1}>
						<MKTypography variant='caption' color='dark'>
							{query?.color?.name.trim()}
						</MKTypography>
						<Box
							sx={{
								width: 14,
								height: 14,
								backgroundColor: query?.color?.hex,
							}}
						/>
					</Stack>
				</Stack>
				<Stack direction='column' sx={{ width: 150 }}>
					<MKTypography variant='caption' color='dark'>
						Product
					</MKTypography>
					<MKTypography variant='caption' color='dark'>
						{query?.product?.name}
					</MKTypography>
				</Stack>
				<Stack direction='column'>
					<MKTypography variant='caption' color='dark'>
						Quality
					</MKTypography>
					<MKTypography variant='caption' color='dark'>
						{query.quality}
					</MKTypography>
				</Stack>
				<Stack
					direction='column'
					sx={{
						alignItems: "center",
						display: "flex",
					}}
				>
					<MKTypography variant='caption' color='dark'>
						Quantity
					</MKTypography>
					<MKTypography variant='caption' color='dark' fontWeight='bold'>
						{query?.quantity}
					</MKTypography>
				</Stack>
				<Stack
					direction='column'
					sx={{
						alignItems: "flex-start",

						display: "flex",
					}}
				>
					<MKTypography variant='caption' color='dark'>
						Unavailable
					</MKTypography>
					<Checkbox />
				</Stack>
				<Stack
					sx={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
					}}
					gap={2}
				>
					<Stack
						direction='column'
						sx={{
							alignItems: "flex-start",
							display: "flex",
						}}
						gap={1}
					>
						<MKTypography variant='caption' color='dark'>
							Price/Unit
						</MKTypography>
						<Stack direction={"row"}>
							<MKInput
								placeholder='AED'
								type='number'
								size='small'
								sx={{ width: 80 }}
								disabled={countdown == "Expired"}
								onChange={(e) => {
									setUnitPrice(e.target.value);
								}}
							/>
						</Stack>
						<MKTypography variant='caption' color='dark' fontWeight='bold'>
							Total AED {query?.quantity * unitPrice}
						</MKTypography>
					</Stack>
					<Stack
						direction='column'
						sx={{
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
						gap={1}
					>
						<MKButton
							variant='contained'
							color='primary'
							size='small'
							disabled={countdown == "Expired"}
							onClick={handleSubmit}
						>
							Submit
						</MKButton>
					</Stack>
				</Stack>
				<Stack>
					<MKTypography variant='caption' color='dark'>
						Expires In
					</MKTypography>
					<Typography
						variant='body2'
						style={{ color: "#F2727E" }}
						fontWeight={"bold"}
					>
						{countdown}
					</Typography>
				</Stack>
			</Stack>
		</Paper>
	);
};
export default MerchantQueries;

const styles = {
	container: {
		minHeight: "100vh",
		width: "100%",
		padding: 2,
	},
	sideBar: {
		minHeight: "100vh",
		width: "100%",
		padding: 2,
		backgroundColor: "#FBFCFA",
	},
	filter: {
		minHeight: "5vh",
		width: "100%",
		padding: 2,
		backgroundColor: "#FCFCFC",
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		flexDirection: "row",
	},
	queryRow: {
		width: "100%",
		padding: 2,
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		flexDirection: "row",
		marginTop: 1,
		backgroundColor: "#FEFCFC",
	},
};
